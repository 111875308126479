.custom-content-box {
    background-position: center;
    background-size: cover;
    border-radius: var(--ate-border-radius);
    border: 1px solid var(--ate-widget-border);
    padding: var(--ui-spacing-lg);
    color: var(--ui-color-typography-negative);
    box-shadow: var(--shadow4);

    .description-container {
        margin-top: var(--ui-spacing-md);
    }
}
